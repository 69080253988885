.App {
  height: auto;
  text-align: center;
}

.App-new-image {
   height: 35vmin;
  /* width: 30vmin;  */
  /* height: auto; */
  /* display: row;  */
/*  grid-template-columns: repeat(2, auto);*/
  /* flex-direction: row;
  /* align-items: center;*/
  /* margin: 0; */
  justify-content: center;  
  /* border-top:  green; */
  border-bottom:  solid double white;
   /* border-bottom: 1rem solid green;   */
}

.App-space {
  height: 4vmin;
 /* width: 30vmin;  */
 /* height: auto; */
 /* display: row;  */
/*  grid-template-columns: repeat(2, auto);*/
 /* flex-direction: row;
 /* align-items: center;*/
 /* justify-content: center;  
 border-top:  green;
 border-bottom:  double green; */
  /* border-bottom: 1rem solid green;   */
}


.App-logo {
  height: 35vmin;
}

.App-head {
  height: .5vmin;
 background-color: forestgreen;
}


.App-header {
  
  /*//background-color: #282c34;*/
  min-height: 0vh;
/*  display: table-row-group;*/
  display: table-column-row; 
/*  grid-template-columns: repeat(2, auto);*/
  flex-direction: row;
  align-items: center;
  justify-content: center;

  font-size: calc(10px + 2vmin);
  color: white;
}


.App-image {
  height: 20vmin;
  transition: transform .7s ease-in-out;
  padding: 0;
}

.App-image:hover {
  transform: rotate(360deg);
}


.App-footer {
   height: 15vmin; 
 /* background-color: forestgreen;*/
/*  display: table-row-group;*/
  display: grid;
  grid-gap: 0px; 
  grid-template-columns: repeat(5, auto);
  flex-direction: row;
  align-items: center;
  justify-content: center;

  font-size: calc(10px + 2vmin);
  color: white;
   }

@media (prefers-reduced-motion: no-preference) {
  /* .App-logo {
    animation: App-logo- infinite 50s linear;
  } */
}


@media (prefers-reduced-motion: no-preference) {
  /* .App-image {
    animation: App-image-spin infinite 60s linear;
  } */
}

.App-link {
  color: #09d3ac;
}

.carousel-wrapper {
  /*max-width: 1080px;*/
  /* max-width: auto;
  margin: 20px auto;
  padding: 0 10px */

  /* max-width: 800px; */
  max-width: auto;
  margin: 10px auto;
  padding: 0 10px
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@keyframes App-image-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
